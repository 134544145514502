import { render, staticRenderFns } from "./onlineUsers.vue?vue&type=template&id=24f26b5b&scoped=true&"
import script from "./onlineUsers.vue?vue&type=script&lang=js&"
export * from "./onlineUsers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24f26b5b",
  null
  
)

export default component.exports
<template>
    <section class="cont">
       <!-- 面包屑 -->
       <el-row class="crumbs-box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
             <el-breadcrumb-item>系统管理</el-breadcrumb-item>
             <el-breadcrumb-item>在线用户</el-breadcrumb-item>
          </el-breadcrumb>
       </el-row>

       <el-row class="content-box">
          <!-- 查询框 -->
          <el-row class="search-box">
             <el-row class="search-row">
                <el-row class="search-item">
                   <label>用户名：</label>
                   <el-input class="width-220"  v-model="accUser" :placeholder="$t('msg.user_name_nickname')"  clearable></el-input>
                </el-row>
                <el-button class="bg-gradient" type="primary" icon="el-icon-search" @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
                <el-button  type="primary" icon="el-icon-refresh-right" plain @click="handleQuery(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
             </el-row>
          </el-row>
          <!-- 主体内容 -->
          <el-row class="table-box">
             <!-- 表格按钮 -->
             <div class="table-head-btn">
                <el-row></el-row>
                <el-row class="right-btn">
                   <el-button  type="primary" icon="el-icon-refresh-right" plain @click="handleRefresh"><span v-text="$t('msg.refresh')">刷新</span></el-button>
                </el-row>
             </div>
            <el-table
                    v-loading="loading"
                    :data="tableData"
                    border fit
                    highlight-current-row
                    style="width: 100%"
                    :stripe="true"
                    >
               <el-table-column prop="number" :label="$t('msg.number')" width="70">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
                <el-table-column :label="$t('msg.head_portrait')" width="80">
                   <template slot-scope="scope">
                      <img :src="scope.row.headPortrait" style="height: 35px;border-radius: 50%" alt="">
                   </template>
                </el-table-column>
                <el-table-column prop="userName" :label="$t('msg.user_name')" width="140"></el-table-column>
                <el-table-column prop="nickName" :label="$t('msg.nickname')" min-width="100"></el-table-column>
                <el-table-column prop="system" :label="$t('msg.operating_system')"></el-table-column>
                <el-table-column prop="client" :label="$t('msg.terminal')" width="100"></el-table-column>
                <el-table-column prop="ipAddress" :label="$t('msg.ip_address')" min-width="70"></el-table-column>
                <el-table-column prop="loginTime" :label="$t('msg.login_time')" min-width="110"></el-table-column>
                <el-table-column prop="logoutTime" :label="$t('msg.online_duration')" min-width="110"></el-table-column>
                <el-table-column :label="$t('msg.offline')" min-width="90">
                    <template slot-scope="scope">
                        <el-button @click="handleOffline(scope.row.score)" type="text" icon="el-icon-switch-button" ></el-button>
                    </template>
                </el-table-column>
            </el-table>
              <!-- 分页 -->
             <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
          </el-row>
       </el-row>
    </section>
</template>

<script>
import { urlObj } from '@/api/interface'
import { system } from '@/api/interface/system'
   import common from '@/common/js/common'
export default {
   data(){
      return{
         tableData: [],      // 表格数据
         loading: true,      // 加载动画
         accUser: '',        // 用户名称/昵称
         page: 1,            // 当前页 默认第1页
         limit: 0,           // 每页显示数
         total: 0,           // 总数目数
         offline_success: '用户下线成功！',
      }
   },
   mounted() {
      this.limit = sessionStorage.getItem('pageSize') * 1
      this.getOnlineUsers()
   },
   inject: ['reload'],
   methods: {
      // 获取在线用户列表
      getOnlineUsers(){
         const param = { page: this.page, limit: this.limit }
         const url = system.getOnlineUser
         this.$axios.get(url, param).then(res => {
            if(res.success){
               this.total = res.total
               this.loading = false
               this.tableData = res.records
               this.tableData.forEach(item => {
                  try {
                     item.headPortrait.substr(0,4) !== 'http' && (item.headPortrait = item.headPortrait && window.getResourcesHost() + item.headPortrait)
                  }catch (err) {
                     console.warn('headPortrait undefined')
                  }
               })
            }
         })
      },
      // 搜索在线用户
      handleQuery(bool){
         this.page = 1
         if (bool) return this.getOnlineUsers()
         this.accUser = ''
      },
      // 改变显示条数
      pageChange(v){
         this.limit = v
         this.getOnlineUsers()
      },
      // 改变当前页
      handlePaging(v){
         this.page = v
         this.getOnlineUsers()
      },
      // 踢人下线
      handleOffline(val){
         let scores = []
         scores.push(val)
         const param = { scores: scores.toString() }
         const url = system.offlineUser
         this.$axios.get(url, param).then(res => {
            if (res.success){
               this.$message({ showClose: true, message: this.offline_success, type: 'success'})
               this.getOnlineUsers()
            }
         })
      },
      // 处理刷新
      handleRefresh(){
         this.getOnlineUsers()
      }
   }
}
</script>

<style scoped></style>
